<template>
  <div>
    <Loader />
    <section class="sign-in-page">
      <b-container class="bg-white mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-sm-6 align-self-center">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
          <div class="col-sm-6 text-center">
            <!-- <div class="sign-in-detail text-white" :style="'background: url('+bgImageURL+') no-repeat 0 0; background-size: cover;'"> -->
            <div class="sign-in-detail text-white">
              <a
                class="
                  sign-in-logo
                  mb-5
                  font-weight-bold font-size-40
                  text-white
                "
                href="#"
                ><span>{{ appName }}</span></a
              >
              <Swiper :options="options">
                <SwiperSlide class="item">
                  <img
                    :src="require('@/assets/images/aksara-logo.png')"
                    class="img-fluid mb-4"
                    style="width: 350px"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">Selamat Datang</h4>
                  <p>
                    Aplikasi Layanan informasi dan pendataan kerja sama
                    Universitas Terbuka dengan mitra baik dalam maupun luar
                    negeri.
                  </p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img
                    :src="
                      require('@/assets/images/login/foto-kerjasama-3.jpeg')
                    "
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">Kerjasama UT</h4>
                  <p>
                    Program kerjasama Universitas Terbuka.
                  </p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img
                    :src="
                      require('@/assets/images/login/foto-kerjasama-4.jpeg')
                    "
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">
                    Kerjasama UT dan Pemerintah Kabupaten Bolaang Mongondow
                    Selatan
                  </h4>
                  <p>
                    Penandatanganan atau kesepahaman Universitas Terbuka dengan
                    Pemerintah Kabupaten Bolaang Mongondow Selatan.
                  </p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '@/components/core/loader/Loader'
import logo from '@/assets/images/logo-white.png'
import { core, APPNAME } from '@/config/pluginInit'
import bgImage from '@/assets/images/login/login-2.jpg'
import Swiper from '@/components/core/slider/Swiper'
import SwiperSlide from '@/components/core/slider/SwiperSlide'
export default {
  name: 'AuthLayout',
  components: {
    Loader,
    Swiper,
    SwiperSlide
  },
  mounted() {
    core.index()
  },
  data() {
    return {
      appName: APPNAME,
      options: {
        slidesPerView: 1,
        loop: true,
        autoplay: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
<style>
@import url('../../assets/css/custom.css');
</style>
